import {

	Message
} from 'element-ui'
import {
	handleMatchdetails,
	handleQueryMatchRegulationsByMatchId

} from '@index/api/joinmatch/matchinfo';
import matchtitle from '../../components/matchtitle';
export default {
	name: 'hosterMatchInfo',
	components: {
		matchtitle
	},
	data() {
		return {
			matchinfo: {},
			matchId: sessionStorage.getItem("currMatchId"),
			regulations: '',
		};
	},
	watch: {
		// $route(to, from) {
		//   if (this.$route.query && this.$route.query.menusShow) {
		//     this.$refs.commonHeader.init(this.$route.query.menusShow);
		//   } else {
		//     this.$refs.commonHeader.init(false);
		//   }
		// }
	},
	created() {
		this.matchdetails();
	},
	mounted() {

	},
	destroyed() {
		this.$el.remove();
	},
	methods: {
		matchdetails() { //比赛详情
			handleMatchdetails(this.matchId).then(res => {
				if (res.status == 200) {
					this.matchinfo = res.data;
					//  this.$refs.matchtitleNode.init(this.matchinfo.matchName);
					this.queryMatchRegulationsByMatchId(this.matchinfo.id);
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}
			});
		},
		startSignup() {

			this.$router.push({
				path: '/joinmatchonlinesignup'
			})
		},
		queryMatchRegulationsByMatchId(matchId) { //获取赛事规程
			handleQueryMatchRegulationsByMatchId(matchId).then(res => {
				if (res.status == 200) {
					this.regulations = res.data.regulations;
					//	window.tinymce.get('tinymce').setContent(res.data.regulations);	
				}
			});
		}
	}
}
